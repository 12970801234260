import React from "react";
import "twin.macro";

export const H1 = ({ children, ...props }) => (
  <h1
    {...props}
    tw="font-heading text-4xl md:text-5xl leading-normal text-gray-900 mt-12"
  >
    {children}
  </h1>
);

export const H2 = ({ children, ...props }) => (
  <h2 {...props} tw="font-heading text-3xl leading-normal text-gray-900 mt-12">
    {children}
  </h2>
);

export const H3 = ({ children, ...props }) => (
  <h3 {...props} tw="font-heading text-2xl leading-normal text-gray-900 mt-8">
    {children}
  </h3>
);

export const H4 = ({ children, ...props }) => (
  <h4
    {...props}
    tw="font-body text-base leading-normal text-gray-900 mt-4 font-semibold"
  >
    {children}
  </h4>
);

export const Subtitle = (props) => (
  <p {...props} tw="font-heading text-xl leading-normal text-gray-500 mt-2" />
);

export const P = (props) => (
  <p {...props} tw="font-body text-base leading-relaxed text-gray-700 mt-6" />
);
