import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/gatsby-site/src/components/article-layout.js";
import Quiz from '../../components/quiz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`ตรวจสอบจัมโบ้ อันเดอร์ปอดแหก สแล็กไมเกรนบ๊อบแทคติคสะเด่า สตรอว์เบอร์รีแดนเซอร์ผ้าห่มฮัลโหลเฟิร์ม
บ๊วยแพนงเชิญ โมเต็ลดีเจพะเรอทอล์คโอเวอร์ ต่อรองห่วยอินเตอร์เย้วแดนเซอร์ โยเกิร์ต วีไอพีแต๋วรีโมทฟิวเจอร์วาริชศาสตร์
สจ๊วตคอนโดตรวจทานโชว์รูมหม่านโถว แชมป์ดัมพ์`}</p>
    <p>{`แต่มันก็ไร้ความหมาย`}</p>
    <h2>{`อยากจะบอกอะไรสักหน่อย`}</h2>
    <blockquote>
      <p parentName="blockquote">{`โควตคมๆบาดจิต
วู้วววว `}</p>
    </blockquote>
    <h3>{`นอกจากนี้`}</h3>
    <p>{`ยังมีประเด็นเรื่อง`}</p>
    <ul>
      <li parentName="ul">{`แมว`}</li>
      <li parentName="ul">{`หมา`}</li>
    </ul>
    <ol>
      <li parentName="ol">{`cat`}</li>
      <li parentName="ol">{`dog`}</li>
    </ol>
    <p>{`ก็อาจจะเป็นเรื่องประหลาด`}</p>
    <p><img parentName="p" {...{
        "src": "https://source.unsplash.com/1600x900/?nature,water%22",
        "alt": "some random image of nature"
      }}></img></p>
    <p>{`แบบนี้`}</p>
    <p>{`ต่อไปนี้จะเป็นคำถาม`}</p>

    <Quiz problems={[{
      question: '1+1 เท่ากับ?',
      choices: [1, {
        answer: 2
      }, 3, 4]
    }, {
      question: '2+2 เท่ากับ?',
      choices: [1, 2, 3, {
        answer: 4
      }, 5, 6]
    }, {
      question: '5+5 เท่ากับ?',
      choices: [1, 2, 3, {
        answer: 10
      }, 'ไม่บอก']
    }]} mdxType="Quiz" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      