import "tailwindcss/dist/utilities.min.css";
import tw from "twin.macro";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { MDXProvider } from "@mdx-js/react";

import Header from "./header";
import { H1, H2, H3, H4, Subtitle, P } from "../components/atoms/Text";
import SEO from "./seo";

const CoverImage = ({ coverImage }) => {
  const [isImageLoaded, setImageLoaded] = useState(false);

  return (
    <div css={!isImageLoaded && tw`animate-pulse`}>
      <img
        tw="hidden"
        alt="hidden cover"
        onLoad={() => setImageLoaded(true)}
        src={coverImage}
      />

      <div
        tw="transition duration-300 ease-in-out bg-gray-400 bg-cover bg-center w-full md:h-104 h-64"
        css={
          isImageLoaded
            ? { backgroundImage: `url('${coverImage}')`, opacity: 1 }
            : { opacity: 0.5 }
        }
      />
    </div>
  );
};

const ArticleLayout = ({ children, ...props }) => {
  const { title, subtitle, coverImage } = props.pageContext.frontmatter;
  return (
    <>
      <SEO title={title} description={subtitle} image={coverImage} />
      <Header />
      <CoverImage coverImage={coverImage} />
      <div tw="pt-0 py-8 container mx-auto">
        <MDXProvider
          components={{
            h1: H1,
            h2: H2,
            h3: H3,
            h4: H4,
            ul: (props) => (
              <ul
                {...props}
                tw="list-disc list-inside leading-relaxed mt-2"
              ></ul>
            ),
            ol: (props) => (
              <ul
                {...props}
                tw="list-decimal list-inside leading-relaxed mt-2"
              ></ul>
            ),
            li: (props) => <li {...props} tw="font-body text-gray-700"></li>,
            p: P,
            blockquote: (props) => (
              <blockquote
                {...props}
                tw="px-4 pt-1 pb-8 mx-6 mt-4 border-l-4 border-gray-400 italic"
              />
            ),
            img: (props) => <img alt={props.alt} {...props} tw="my-10" />
          }}
        >
          <header>
            {title && <H1>{title}</H1>}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </header>

          {children}
        </MDXProvider>
      </div>
    </>
  );
};

ArticleLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default ArticleLayout;
